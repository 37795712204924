/* Code for smaller underlines */
.fact__number > a:hover {
  text-decoration: none;
  border-bottom: .05em solid white;
  display: inline-block;
  line-height: .85;
  text-shadow: 2px 2px #003478, 2px -2px #003478, -2px 2px #003478, -2px -2px #003478;
}
/* No underline in email in footer */
.no-underline {
  text-decoration: none !important;
}
.no-underline:hover {
  text-decoration: underline !important;
}

/* Form styles added by HH */
button.default {
  box-sizing: border-box;
  padding: 6px 12px 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(166, 166, 166);
  margin-bottom: 30px;
}

.form_pages textarea {
  border-width: 1px;
}

div.form_page input {
  padding: 1px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(166, 166, 166);
}

div.form_page select{
  border-width: 1px;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  border-color: rgb(166, 166, 166);
  border-radius: 5px;
}

/* Photo Credit */
.photo-credit-caption {
  display: block;
  margin-top: -.75rem;
  font-family: 'Whitney SSm A', 'Whitney SSm B', sans-serif;
  font-weight: 500;
  font-size: .75rem!important
}

.photo-credit {
  display: block;
  margin-top: .75rem;
  font-family: 'Whitney SSm A', 'Whitney SSm B', sans-serif;
  font-weight: 500;
  font-size: .75rem!important
}

/* Beige Background for paragraphs instead of blockquotes */
.beige--block {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  padding: 1.25rem;
  background: #f5f4f0;
}

.faculty__listing:after {
  clear: both;
  content: '';
  display: block;
}

// .faculty__listing p:first-of-type { changed from last-child to first-of-type
//   margin-top: 2rem
// }
